/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useState } from 'react'
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  Grid,
} from '@mui/material'
import isEmpty from 'lodash.isempty'
import clsx from 'clsx'
import Image from 'next/image'
import GreyHeart from 'src/assets/svgs/icons/greyHeart'
import Tag from 'src/components/shared/atoms/Tag/Tag'
import { useRouter } from 'next/router'
import Moment from 'react-moment'
import DefaultImage from 'src/assets/images/default.jpg'
import type { SearchArticlesRes } from 'src/types'
import useStyles from './Style'
import {
  useDisLikeArticleMutation,
  useLikeArticleMutation,
  useUpdateArticlesMutation,
} from 'src/store/ApiCall/articleAPI'
import { v4 as uuid } from 'uuid'
import aa from 'search-insights'
import { getCookie } from 'cookies-next'
import Play from 'src/assets/images/partner/play.svg'

aa('init', {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
})
interface ArticleCardProps {
  data: SearchArticlesRes
  style?: CSSProperties
  zenMode?: boolean
  queryID?: string | undefined
  favCardStyles?: boolean
}

const SearchResultCard: React.FC<ArticleCardProps> = ({
  data,
  style,
  zenMode = false,
  queryID: sQueryId,
  favCardStyles,
}) => {
  const {
    id,
    title,
    slug,
    image,
    pubblishedAt,
    tags,
    _highlightResult,
    videoURL,
  } = data
  const { classes } = useStyles()
  const router = useRouter()
  const [like, setLike] = useState<boolean>(data.isLiked)
  const [videoPlay, setVideoPlay] = useState<boolean>(false)
  const [disLike] = useDisLikeArticleMutation()
  const [likeArticle] = useLikeArticleMutation()
  const [updateArticle] = useUpdateArticlesMutation()
  const [articleLikeCount, setArticleLikeCount] = useState<number>(
    data.likes_count ? data.likes_count : 0
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loggedInUser, setLoggedInUser] = React.useState<any>()
  const [runQuery, setRunQuery] = useState<boolean>(false)
  const anonymousUserToken = getCookie('anonymousUserToken')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function updateArticleHandler(data: any) {
    const response = await updateArticle(data).unwrap()
    return response
  }

  React.useEffect(() => {
    if (runQuery) {
      updateArticleHandler({
        data: {
          id: data.id,
          article_like_count: articleLikeCount,
        },
      })
    }
  }, [articleLikeCount])

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON = localStorage.getItem('dtl_token')

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  React.useEffect(() => {
    if (data.isLiked) setLike(true)
    else setLike(false)
  }, [data])
  const handleClick = (slug: string | undefined, id: number) => {
    ///if user is on search page trigger Searched_Clicked_Article
    const uID = uuid()
    if (router.pathname === '/search') {
      if (loggedInUser) {
        aa('setUserToken', loggedInUser.toString())
        aa('convertedObjectIDsAfterSearch', {
          userToken: '' + loggedInUser,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Searched_Clicked_Article',
          objectIDs: [id + ''],
          queryID: sQueryId ? sQueryId.toString() : '',
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })
        ///// clickedObjectIDs

        aa('clickedObjectIDs', {
          userToken: '' + loggedInUser,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Searched_Clicked_Article',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })

        ////   viewedObjectIDs
        aa('viewedObjectIDs', {
          userToken: '' + loggedInUser,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Article_View',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })
      } else {
        aa('convertedObjectIDsAfterSearch', {
          userToken:
            anonymousUserToken && typeof anonymousUserToken === 'string'
              ? anonymousUserToken
              : uID,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Searched_Clicked_Article',
          objectIDs: [id + ''],
          queryID: sQueryId ? sQueryId.toString() : '',
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })

        ///// clickedObjectIDs
        aa('clickedObjectIDs', {
          userToken:
            anonymousUserToken && typeof anonymousUserToken === 'string'
              ? anonymousUserToken
              : uID,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Searched_Clicked_Article',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })

        ////   viewedObjectIDs
        aa('viewedObjectIDs', {
          userToken:
            anonymousUserToken && typeof anonymousUserToken === 'string'
              ? anonymousUserToken
              : uID,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Article_View',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })
      }

      if (id) {
        router.push(`${slug}`)
        return
      }
    } else {
      ///if user is on search page trigger Clicked_Article

      if (loggedInUser) {
        aa('setUserToken', loggedInUser.toString())

        aa('convertedObjectIDsAfterSearch', {
          userToken: '' + loggedInUser,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Clicked_Article',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
          queryID: sQueryId ? sQueryId.toString() : '',
        })
        ///// clickedObjectIDs

        aa('clickedObjectIDs', {
          userToken: '' + loggedInUser,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Clicked_Article',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })

        ////   viewedObjectIDs
        aa('viewedObjectIDs', {
          userToken: '' + loggedInUser,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Clicked_Article',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })
      } else {
        aa('convertedObjectIDsAfterSearch', {
          userToken:
            anonymousUserToken && typeof anonymousUserToken === 'string'
              ? anonymousUserToken
              : uID,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Clicked_Article',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
          queryID: sQueryId ? sQueryId.toString() : '',
        })

        ///// clickedObjectIDs

        aa('clickedObjectIDs', {
          userToken:
            anonymousUserToken && typeof anonymousUserToken === 'string'
              ? anonymousUserToken
              : uID,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Clicked_Article',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })

        ////   viewedObjectIDs
        aa('viewedObjectIDs', {
          userToken:
            anonymousUserToken && typeof anonymousUserToken === 'string'
              ? anonymousUserToken
              : uID,
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'Article_View',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })
      }

      if (id) {
        router.push(`/${slug}`)
        return
      }
    }

    return
  }

  React.useEffect(() => {
    if (runQuery) {
      updateArticleHandler({
        data: {
          id: data.id,
          article_like_count: articleLikeCount,
        },
      })
    }
  }, [articleLikeCount])

  const tagHandler = (e: React.SyntheticEvent, content = '') => {
    e.stopPropagation()
    if (!content || isEmpty(content)) return
    if (content.includes('#') && content.slice(1) === '#') {
      router.push(`/${content.slice(1)}-Support-Hub`)
      return
    }
    router.push(`/${content}-Support-Hub`)
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function disLikeHandler(data: any) {
    const response = await disLike(data).unwrap()
    return response
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function likeArticleHandler(data: any) {
    const response = await likeArticle(data).unwrap()
    if (response) {
      aa('setUserToken', loggedInUser.toString())
      aa('clickedObjectIDs', {
        userToken: '' + loggedInUser.toString(),
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'article_like',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })

      aa('convertedObjectIDs', {
        userToken: '' + loggedInUser.toString(),
        index:
          getCookie('lang') == 'En' ? 'search_articles' : 'mx_search_articles',
        eventName: 'article_like',
        objectIDs: [id + ''],
        headers: {
          'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
          'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        },
      })
    }
    return response
  }

  const heartIconHandler = async (event: React.SyntheticEvent) => {
    event.stopPropagation()
    if (!loggedInUser) {
      router.push(`/auth/login`)
      return
    }
    if (like) {
      // post request to delete record from article like where userId , articleId
      //after record setLike to false
      const response = await disLikeHandler({
        articleId: data.id,
        userId: loggedInUser,
      })

      if (response) {
        setRunQuery(true)
        setLike(false)
        const updatedCount = articleLikeCount - 1
        setArticleLikeCount(updatedCount)
      }
    } else {
      // add a post request to add record in article like tabble using userId ,article Id
      //after record setLike to true
      const response = await likeArticleHandler({
        data: {
          article: data.id,
          dtl_user: loggedInUser,
        },
      })
      if (response) {
        setRunQuery(true)
        setLike(true)
        const updatedCount = articleLikeCount + 1
        setArticleLikeCount(updatedCount)
      }
    }
  }

  if (!data) {
    return <></>
  }

  return (
    <Card
      // onClick={() => handleClick(slug, id)}
      className={`${classes.cardContainer} ${
        zenMode && classes.zenModeCardContainer
      }`}
      sx={{ ...style }}
    >
      <Grid
        className={`${classes.cardImageContainer} ${
          zenMode && classes.zenModeImgContainer
        } ${favCardStyles && classes.favCardContainer}`}
      >
        {videoURL && videoPlay ? (
          <iframe
            width="100%"
            height="100%"
            src={videoURL}
            title="article video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        ) : videoURL && !videoPlay ? (
          <div
            style={{
              position: 'relative',
              width: '100%',

              height: '100%',
            }}
          >
            <div
              className={classes.outerBox}
              onClick={() => setVideoPlay(true)}
            >
              <div className={`${classes.innerBox} `}>
                <Image src={Play?.src} alt="play" fill />
              </div>
            </div>
            <Image
              className={`${classes.cardImage} ${
                zenMode && classes.zenModeImgContainer
              }`}
              src={image ? image : DefaultImage.src}
              alt={title ? title : _highlightResult.title?.value}
              fill
            />
          </div>
        ) : (
          <Image
            onClick={() => handleClick(slug, id)}
            className={`${classes.cardImage} ${
              zenMode && classes.zenModeImgContainer
            }`}
            src={image ? image : DefaultImage.src}
            alt={title ? title : _highlightResult.title?.value}
            fill
          />
        )}
      </Grid>

      <CardContent
        onClick={() => handleClick(slug, id)}
        className={classes.cardContentContainer}
      >
        <Box className={classes.cardDateContainer}>
          <span className={classes.cardDate}>
            {pubblishedAt ? (
              <Moment format="MMMM DD, YYYY">{pubblishedAt}</Moment>
            ) : _highlightResult?.publishedAt?.value ? (
              <Moment format="MMMM DD, YYYY">
                {_highlightResult?.publishedAt?.value}
              </Moment>
            ) : null}
          </span>
          <span className={classes.cardHeartIcon} onClick={heartIconHandler}>
            <GreyHeart fill={like ? 'black' : 'none'} />
          </span>
        </Box>
        <Typography className={classes.cardText}>
          {title ? title : _highlightResult.title?.value}
        </Typography>
      </CardContent>
      <CardActions
        onClick={() => handleClick(slug, id)}
        className={clsx(
          classes.cardBtnContainer,
          _highlightResult && classes.searchClass
        )}
      >
        {tags
          ? tags.map((el: string, index: number) => (
              <Tag
                onClickHandler={true}
                key={index}
                content={el}
                id={index}
                color="black"
                background="transparent"
                onClick={(e: React.SyntheticEvent) => tagHandler(e, el)}
              />
            ))
          : ''}
      </CardActions>
    </Card>
  )
}

export default SearchResultCard
