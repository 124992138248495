import React from 'react'

interface IProps {
  fill?: string
}
const GreyHeart: React.FC<IProps> = ({ fill }) => {
  return (
    <div>
      <svg
        width="21"
        height="17"
        viewBox="0 0 21 17"
        fill={fill ? fill : 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0042 16.4995V16.4995L9.99845 16.4995C9.93264 16.4999 9.86741 16.4873 9.80649 16.4624C9.74594 16.4377 9.69083 16.4013 9.64428 16.3554C9.644 16.3551 9.64372 16.3548 9.64345 16.3545L1.87672 8.57782C1.87648 8.57757 1.87623 8.57732 1.87599 8.57708C0.994457 7.68594 0.5 6.48304 0.5 5.22953C0.5 3.97622 0.994297 2.7735 1.87556 1.88241C2.76398 0.997117 3.96708 0.5 5.22134 0.5C6.47591 0.5 7.6793 0.997368 8.56778 1.88308C8.56795 1.88325 8.56812 1.88341 8.56828 1.88358L9.64778 2.96308L10.0013 3.31664L10.3549 2.96308L11.4344 1.88358C11.4346 1.88341 11.4347 1.88325 11.4349 1.88308C12.3234 0.997368 13.5268 0.5 14.7813 0.5C16.0356 0.5 17.2387 0.997123 18.1271 1.88243C19.0084 2.77352 19.5027 3.97623 19.5027 5.22953C19.5027 6.48306 19.0082 7.68598 18.1266 8.57712C18.1264 8.57735 18.1262 8.57758 18.1259 8.57782L10.3592 16.3545C10.3589 16.3548 10.3587 16.3551 10.3584 16.3554C10.3118 16.4013 10.2567 16.4377 10.1962 16.4624C10.1353 16.4873 10.07 16.4999 10.0042 16.4995Z"
          stroke="black"
        />
      </svg>
    </div>
  )
}

export default GreyHeart
