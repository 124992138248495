import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
import { pxToRem } from '@styles/function'
const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'relative',
    left: '2rem',
    [theme.breakpoints.down('sm')]: {
      left: '2rem',
    },
  },
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  pagesBtn: {
    border: 'none',
    borderLeft: `${pxToRem(1)} solid #000`,
    paddingLeft: pxToRem(10),
    paddingRight: pxToRem(10),
    background: 'none',
  },
  prevNextBtn: {
    border: 'none',
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19.1),
    paddingLeft: pxToRem(20),
    paddingRight: pxToRem(20),
    textTransform: 'uppercase',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      paddingLeft: pxToRem(10),
      paddingRight: pxToRem(10),
    },
  },
}))

export default useStyles
