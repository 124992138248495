import React from 'react'
import usePagination from '@mui/material/usePagination'
import useStyles from './Style'
// import _ from 'lodash'
import isEqual from 'lodash.isequal'
import { getCookie } from 'cookies-next'

interface IProps {
  handleClick: (page: number | null) => void
  pageCount: number
  page: number
}
const UsePagination: React.FC<IProps> = ({ handleClick, pageCount, page }) => {
  const { classes } = useStyles()
  const isBrowser = typeof window === 'object' ? true : false
  const lang = getCookie('lang')

  const { items } = usePagination({
    count: pageCount,
    defaultPage: 0,
    page: page,
  })

  // const [items, setitems] = React.useState<any>({})

  // React.useEffect(() => {

  //   const { items } = usePagination({
  //     count: pageCount,
  //     defaultPage: 0,
  //     page,
  //   })
  //   // setAlgoliaQuery(q)
  // }, [])

  return (
    <nav className={classes.wrapper}>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <div style={{ marginRight: '10px' }}>...</div>
          } else if (type === 'page') {
            children = (
              <span onClick={() => handleClick(page)}>
                <button
                  type="button"
                  className={classes.pagesBtn}
                  style={{
                    fontWeight: selected ? 'bold' : undefined,
                    cursor: 'pointer',
                  }}
                  {...item}
                >
                  {page}
                </button>
              </span>
            )
          } else {
            children = (
              <span onClick={() => handleClick(page)}>
                <button
                  className={classes.prevNextBtn}
                  type="button"
                  {...item}
                  style={{ cursor: 'pointer' }}
                >
                  {isBrowser && lang === 'En'
                    ? type
                    : index === 0
                    ? 'ANTERIOR'
                    : 'SIGUIENTE'}
                </button>
              </span>
            )
          }

          return <li key={index}>{children}</li>
        })}
      </ul>
    </nav>
  )
}

function areEqual(prevProps: IProps, nextProps: IProps) {
  return isEqual(prevProps, nextProps)
  // && prevProps.pageCount !== nextProps.pageCount
}

export default React.memo(UsePagination, areEqual)
