import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
import { pxToRem } from '@styles/function'
const useStyles = makeStyles()((theme) => ({
  cardContainer: {
    background: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
    marginRight: pxToRem(20),
    marginBottom: pxToRem(25),
    position: 'relative',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginRight: pxToRem(10),
    },
  },
  zenModeCardContainer: {
    border: '1px solid black',
    padding: theme.spacing(5),
    '& img': {
      display: 'none',
    },
  },
  zenModeImgContainer: {
    display: 'none',
    '& img': {
      display: 'none',
    },
  },
  cardImageContainer: {
    position: 'relative',
    borderRadius: '20px',
    overflow: 'hidden',
    width: '100%',
    height: '235px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '151px',
    },
    marginBottom: '20px',
  },
  favCardContainer: {
    height: '357px',
    [theme.breakpoints.down('md')]: {
      height: '151px',
    },
  },
  cardImage: {
    width: '100%',

    marginBottom: pxToRem(20),
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: pxToRem(151),
    },
  },
  cardContentContainer: {
    padding: 0,
    zIndex: 100,
  },
  cardText: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(24),
    fontWeight: '800',
    lineHeight: pxToRem(29),
    letterSpacing: '0em',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      fontSize: pxToRem(22),
    },
  },
  outerBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // borderRadius: '50%',
    // background: 'rgba(0, 0, 0, 0.32)',
    zIndex: 1,
  },
  innerBox: {
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: '62px',
    height: '62px',
    // background: 'rgba(0, 0, 0, 0.92);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // transition:'transform .2s ease',
  },
  cardDateContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: pxToRem(12),
    zIndex: 100,
  },
  cardDate: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(12),
    fontWeight: '700',
    lineHeight: pxToRem(16),
    letterSpacing: '0.02em',
    textAlign: 'center',
    color: '#818181',
    display: 'none',
  },
  cardHeartIcon: {
    color: '#818181',
    width: pxToRem(20),
    height: pxToRem(17),
  },
  cardBtnContainer: {
    width: '100%',
    // maxWidth: '345px',
    height: 'auto',
    maxHeight: '100px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 0,
  },
  searchClass: {
    paddingTop: theme.spacing(4),
  },
}))

export default useStyles
